import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import ArrowWhite from "../../images/arrow-white.svg"

const HomeHero = () => {
  return (
    <div className="section home-header">
      <div className="dark-overlay-60 fade-in" />
      <div className="header-top-overlay" />
      <div className="nav-height" />
      <div className="wrap-block">
        <div>
          <h1>
            Bilişim Avukatı <br /> Bilişim Davaları
          </h1>
          <p>
            BT ve Teknoloji Avukatları, Her büyüklükteki teknoloji şirketi için
            uzman hukuki danışmanlık sunuyoruz. Uzman teknolojimiz ve bilişim
            avukatlarımız, yasal gereksinimlerinize yardımcı olacak zengin bir
            deneyime sahiptir.
          </p>
          <Link to="/iletisim" className="btn white black-medium-right">
            İletişime Geç
          </Link>
        </div>
      </div>
      <div className="header-cubes">
        <StaticImage
          className="img"
          src="../../images/hero-image.png"
          alt="Bilişim Avukatı, Sosyal Medya Avukatı, Fenomen Avukatı, İnternet Avukatı, Teknoloji Avukatı"
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
        />
      </div>
      <div className="home-header-bottom">
        <div className="wrap-block">
          <a href="#intro" className="scroll-down">
            <div className="arrow-scroll">
              <ArrowWhite />
            </div>
            Aşağı Kaydır
          </a>
        </div>
      </div>
    </div>
  )
}

export default HomeHero
