import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { Hero, About } from "../components/Home/index"
import { Steps, Services } from "../components/Sections/index"
import BlogCard from "../components/PostCard/PostCardGroup"

import Seo from "../components/seo"

const IndexPage = ({ data: { blog } }) => (
  <Fragment>
    <Seo
      title="Bilişim Avukatı - Bilişim Davaları"
      description="Bilişim ve Teknoloji Avukatları, Her büyüklükteki teknoloji şirketi için uzman hukuki danışmanlık sunuyoruz."
    />
    <Hero />
    <About />
    <Services />
    <Steps />
    <BlogCard posts={blog.edges} />
  </Fragment>
)

export default IndexPage

export const homeQuery = graphql`
  {
    blog: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            slug
            tags
            title
            description
            Image {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  }
`
