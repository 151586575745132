import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HomeAbout = () => {
  return (
    <div className="section landing-steps right">
      <div className="wrap-wide">
        <div className="sub-wrap">
          <div className="inner">
            <h2>Kurumsal</h2>
            <p>
              Avukat Bilişim, bilişim yasasının önemini takdir ediyoruz, bu
              nedenle mevcut en iyi temsili ve korumayı sağlamaya çalışıyoruz.
              Müvekkillerimizin eşit olmayan pazarlık gücüne sahip oldukları
              durumlarda onlara yardımcı olmak, yasal risklerini başarılı bir
              şekilde yönetmelerini ve gelirlerini en üst düzeye çıkarmalarını
              sağlamak için rakipsiz bir deneyim sunmaya çalışıyoruz.
            </p>
            <p>
              Bilişim avukatlarımız, hem İzmir'de hem de Türkiye'nin her yerinde
              bulunan teknoloji şirketlerine sürekli olarak istisnai bir hukuki
              danışmanlık hizmeti sunmaya kararlıdır. Yıllar içinde büyüdükçe
              müşteri tabanımız da büyüdü; ve şimdi yeni kurulan şirketlerden
              KOBİ'lere, bireysel yaratıcılara ve büyük çok uluslu şirketlere
              kadar çok çeşitli işletmelere hizmet veriyoruz.
            </p>
          </div>
        </div>
        <div className="sub-wrap">
          <div className="inner">
            <StaticImage
              className="img"
              src="../../images/bilisim-avukati.png"
              alt="Bilişim Avukatı, Sosyal Medya Avukatı, Fenomen Avukatı, İnternet Avukatı, Teknoloji Avukatı"
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeAbout
